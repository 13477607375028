import * as React from "react";
import EditWorksheetFields from "../EditWorksheetFields";
import { convertUSDateToEU, convertUSDateToEUWithTime } from "../../../../util/DateHelper";
import { setInStrict } from "../../../utils/NestetPropertyHelper";
import OrderType from "../../../fields/OrderType";
import { getAssignmentTypeText } from "../../../utils/assignmentTypeHelper";
import { getMapsUrl } from "../../../utils/LocationHelper";
import {
    calulateTotalPrice,
    calulateTotalPriceExVat
} from "../../../utils/basket";
import { parsePriceAsString } from "../../../utils/vatConverter";
import SearchPane from "../../../base/SearchPane";
import { getOrderPaymentTypeText } from "../../../utils/orderPaymentTypeHelper";
import API from "../../../../api";
import CustomCalendar from "../../../base/CustomCalendar";
import "../../style.css";
import {
    IDropdownOption,
    Stack,
    StackItem,
    Dropdown,
    Dialog,
    DialogType,
    Label,
    DialogFooter,
    DefaultButton,
    TextField
} from "@fluentui/react";
import { formatters } from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/annotated.css";
import "jsondiffpatch/dist/formatters-styles/html.css";
import { formatDate } from "../../../../components/utils/DateHelper";
import OEWindow from "./OEWindow";
import { customerFieldConfig, validate } from "../../../../util/validate";

export interface QuickInfoProps {
    order: Order;
    updateOrder: (order: Order) => void;
    departments: Department[];
}

const QuickInfo: React.FunctionComponent<QuickInfoProps> = props => {
    const [addresses, setAddresses] = React.useState([] as string[]);
    const [showExVat, setShowExVat] = React.useState<boolean>(false);
    const { additionalData, timeAndPlace, customer } = props.order;

    const {
        orderStatus,
        orderType,
        twoManJob,
        created,
        createdDateTime,
        orderNr,
        rekvNr,
        createdBy,
        bookingNr,
        discountPercent,
        discountAmount,
        changes
    } = additionalData;
    const { department, date, from, to } = timeAndPlace;

    const { phone, name } = customer;

    const navLocationCode = department ? department.navLocationCode : "";

    let covered = timeAndPlace.mobileServiceLocation
        ? timeAndPlace.mobileServiceLocation.covered
        : "";
    let address = timeAndPlace.mobileServiceLocation
        ? timeAndPlace.mobileServiceLocation.address
        : "";
    const addressLink = address ? (
        <a target="_blank" href={getMapsUrl(address)}>
            {" "}
            {address}
        </a>
    ) : (
        ""
    );

    const [hideTaskJournalDialog, setHideTaskJournalDialog] = React.useState<
        boolean
    >(true);
    const [taskJournalRecords, setTaskJournalRecords] = React.useState<
        TaskJournal[]
    >([] as TaskJournal[]);

    const [hideDiffLogModal, setHideDiffLogModal] = React.useState<boolean>(
        true
    );
    const [
        taskJournalSelectedDIff,
        setTaskJournalSelectedDIff
    ] = React.useState<string>("");

    const openTaskJournalDiff = (diff: string) => {
        setTaskJournalSelectedDIff(diff);
        setHideDiffLogModal(false);
    };

    const onOpenTaskJournalDiffDismiss = () => {
        setHideDiffLogModal(true);
    };

    const updateValue = (nestedFieldName: string[], value: any): Order => {
        const updatedOrderValue: Order = setInStrict(
            { ...props.order },
            nestedFieldName,
            value
        );

        const changes = [...updatedOrderValue.additionalData.changes];
        changes.push(nestedFieldName[nestedFieldName.length - 1]);
        const uniqueChanges = Array.from(new Set(changes));
        const additionalData = {
            ...updatedOrderValue.additionalData,
            changes: uniqueChanges
        };
        const fullyUpdatedOrder = { ...updatedOrderValue, additionalData };
        props.updateOrder(fullyUpdatedOrder);
        return fullyUpdatedOrder;
    };

    const isFieldChanged = (fieldName: string, changedArray: string[]) => {
        return changedArray.includes(fieldName);
    };

    const updateFieldValue = (nestedFieldName: string[]) => (value: any) => {
        updateValue(nestedFieldName, value);
    };

    const changeInput = (nestedFieldName: string[]) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateValue(nestedFieldName, event.currentTarget.value);
    };

    const changeDropdownBool = (nestedFieldName: string[]) => (
        event: React.FormEvent<HTMLDivElement>,
        option: IDropdownOption
    ) => {
        updateValue(nestedFieldName, option.key === "true" ? true : false);
    };

    const onAddressChange = async (address: string) => {
        if (address) {
            const result = await API.AddressApi.addressLookup(
                address +
                    (address.toLowerCase().indexOf("denmark") !== -1
                        ? ",Denmark"
                        : "")
            );
            setAddresses(result.map(x => x.description));
        }
    };

    const onShowTotalExVat = () => setShowExVat(true);

    const onSetAddressValue = (address: string) => {
        updateValue(
            ["timeAndPlace", "mobileServiceLocation", "address"],
            address
        );
    };

    const onOpenTaskJournalDismiss = () => {
        setHideTaskJournalDialog(true);
    };

    const onOpenTaskJournalClick = React.useCallback(() => {
        const orderId = props.order.additionalData.orderId;
        API.TaskJournalApi.getRecords(orderId).then(setTaskJournalRecords);
        setHideTaskJournalDialog(false);
    }, [props.order]);

    let isFinishedOrCancelled =
        orderStatus === "FINISHED" || orderStatus === "CANCELLED"
            ? true
            : false;

    const locations = props.departments.map(department => {
        return { key: department.navLocationCode, text: department.name };
    });

    if (!orderNr) {
        return null;
    }
    let baseProps: Record<string, any> = {
        orderNr: {
            title: "OrdreNr:",
            previewValue: orderNr ? orderNr : "",
            changed: false,
            editDisabled: true
        },
        from: {
            title: "Fra:",
            previewValue: from ? from : "",
            changed: isFieldChanged("from", changes),
            editDisabled: true,
            onChange: changeInput(["timeAndPlace", "from"])
        },
        orderDate: {
            title: "Dato:",
            previewValue: convertUSDateToEU(date),
            changed: isFieldChanged("date", changes),
            editDisabled: true
        },
        to: {
            title: "Til:",
            previewValue: to ? to : "",
            changed: isFieldChanged("to", changes),
            editDisabled: true,
            onChange: changeInput(["timeAndPlace", "to"])
        },
        name: {
            title: "Kunde:",
            previewValue: name,
            changed: isFieldChanged("name", changes),
            editDisabled: isFinishedOrCancelled,
            onChange: changeInput(["customer", "name"])
        },
        phone: {
            title: "Kundens tlf:",
            previewValue: <a href={`tel:${phone}`}>{phone}</a>,
            changed: isFieldChanged("phone", changes),
            editDisabled: false,
            onChange: changeInput(["customer", "phone"])
        },
        rekvNr: {
            title: "Rekv.nr:",
            previewValue: rekvNr,
            changed: isFieldChanged("rekvNr", changes),
            editDisabled: false,
            onChange: changeInput(["additionalData", "rekvNr"])
        },
        createdBy: {
            title: "Booket af:",
            previewValue: createdBy,
            changed: isFieldChanged("createdBy", changes),
            editDisabled: true
        },
        historik: {
            title: "Historik:",
            alwaysShow: true,
            previewValue: (
                <a href="#" onClick={onOpenTaskJournalClick}>
                    vis
                </a>
            ),
            editDisabled: true
        },
        departmentName: {
            title: "Afdeling:",
            previewValue: department ? department.name : "",
            editDisabled: true
        },
        navLocationCode: {
            title: "Lokationskode:",
            previewValue: department ? department.navLocationCode : "",
            changed: isFieldChanged("department", changes),
            editDisabled: true,
            options: locations,
            defaultSelectedKey: navLocationCode
        },
        orderType: {
            title: "OrdreType:",
            previewValue: getAssignmentTypeText(orderType),
            changed: isFieldChanged("orderType", changes),
            editDisabled: isFinishedOrCancelled,
            onChange: updateFieldValue(["additionalData", "orderType"])
        },
        bookingNr: {
            title: "Booking nr:",
            previewValue: bookingNr,
            changed: isFieldChanged("bookingNr", changes),
            editDisabled: false,
            onChange: changeInput(["additionalData", "bookingNr"])
        },
        discountAmount: {
            title: "Rabat beløb:",
            previewValue: discountAmount.toString(),
            changed: isFieldChanged("discountAmount", changes),
            editDisabled: isFinishedOrCancelled,
            onChange: changeInput(["additionalData", "discountAmount"])
        },
        discountPercent: {
            title: "Rabat %:",
            previewValue: discountPercent.toString(),
            changed: isFieldChanged("discountPercent", changes),
            editDisabled: isFinishedOrCancelled,
            onChange: changeInput(["additionalData", "discountPercent"])
        },
        twoManJob: {
            title: "To-mands job:",
            previewValue: twoManJob !== false ? "Ja" : "Nej",
            changed: isFieldChanged("twoManJob", changes),
            editDisabled: false,
            onChange: changeDropdownBool(["additionalData", "twoManJob"]),
            options: [
                { key: "false", text: "Nej" },
                { key: "true", text: "Ja" }
            ]
        },
        totalPrice: {
            title: "Kontant:",
            previewValue: (
                <>
                    {parsePriceAsString(
                        calulateTotalPrice(
                            props.order.items,
                            discountAmount,
                            discountPercent
                        )
                    )}
                    <span className="value">
                        <a href="#" onClick={onShowTotalExVat}>
                            Vis ekskl. moms
                        </a>
                    </span>
                </>
            ),
            changed: false,
            editDisabled: true
        },
        totalPriceExVat: {
            title: "Total ekskl. moms:",
            previewValue: parsePriceAsString(
                calulateTotalPriceExVat(
                    props.order.items,
                    discountAmount,
                    discountPercent
                )
            ),
            changed: false,
            editDisabled: true
        },
        paymentMethod: {
            title: "Betalingsmåde:",
            previewValue: getOrderPaymentTypeText(
                props.order.additionalData.whoIsPaying
            ),
            changed: false,
            editDisabled: true
        },
        created: {
            title: "Oprettet:",
            previewValue: convertUSDateToEUWithTime(createdDateTime),
            changed: false,
            editDisabled: true
        },
        mobileServiceCovered: {
            title: "Overdækket:",
            previewValue: covered !== false ? "Ja" : "Nej",
            changed: isFieldChanged("covered", changes),
            editDisabled: true,
            options: [
                { key: "false", text: "Nej" },
                { key: "true", text: "Ja" }
            ],
            onChange: changeDropdownBool([
                "timeAndPlace",
                "mobileServiceLocation",
                "covered"
            ]),
            defaultSelectedKey: covered.toString()
        },
        mobileServiceAddress: {
            title: "Adresse:",
            previewValue: addressLink,
            changed: isFieldChanged("address", changes),
            editDisabled: true,
            items: addresses,
            onValueChange: onAddressChange,
            onValueSelected: onSetAddressValue
        },
      
    };
    if(props.order.notificationStatus && props.order.notificationStatus.smsOffer)
    {
        baseProps["smsOffer"] = {
            title: "SMS tilbud den:",
            previewValue:  formatDate(props.order.notificationStatus.smsOffer),
            editDisabled: true,
            changed: false,
        };
    }
    var phonevalidation = customerFieldConfig["phone"];
   
    return (
        <div className="work__details">
            <Stack horizontal>
                <StackItem grow={1}>
                    <EditWorksheetFields>
                        <p {...baseProps["orderNr"]}></p>
                        <input
                            {...baseProps["from"]}
                            type="time"
                            defaultValue={from}
                        />
                        <CustomCalendar
                            {...baseProps["orderDate"]}
                            department={department}
                            changeDate={updateFieldValue([
                                "timeAndPlace",
                                "date"
                            ])}
                            selectedDate={date}
                        />
                        <input {...baseProps["to"]} type="time" value={to} />
                        <OrderType
                            {...baseProps["orderType"]}
                            value={orderType}
                        />
                        <input
                            {...baseProps["bookingNr"]}
                            type="number"
                            defaultValue={bookingNr}
                        />
                        <input
                            {...baseProps["name"]}
                            type="text"
                            defaultValue={name}
                        />
                        <TextField
                            validateOnLoad={true}
                            onGetErrorMessage={validate(phonevalidation.validation)}
                            title={phonevalidation.placeholder}
                            type={phonevalidation.type}
                            placeholder={phonevalidation.placeholder}
                            value={phone}
                            onChange={baseProps["phone"].onChange}
                         />
                        {baseProps["smsOffer"] && <p {...baseProps["smsOffer"]}></p>}
                     
                      
                    </EditWorksheetFields>
                    <OEWindow order={props.order} updateOrder={props.updateOrder}/>
                </StackItem>
                <StackItem grow={1}>
                    <EditWorksheetFields>
                        <input
                            {...baseProps["rekvNr"]}
                            type="text"
                            defaultValue={rekvNr}
                        />
                        <p {...baseProps["departmentName"]}></p>
                        <p {...baseProps["navLocationCode"]}></p>
                        <input
                            {...baseProps["discountAmount"]}
                            type="number"
                            defaultValue={discountAmount.toString()}
                        />
                        <input
                            {...baseProps["discountPercent"]}
                            type="number"
                            defaultValue={discountPercent.toString()}
                        />
                        <Dropdown
                            calloutProps={{
                                calloutMaxHeight: 250,
                                calloutMinWidth: 100
                            }}
                            {...baseProps["twoManJob"]}
                            dropdownWidth={200}
                            defaultSelectedKey={twoManJob.toString()}
                        />
                        <p {...baseProps["totalPrice"]}></p>
                        {showExVat && <p {...baseProps["totalPriceExVat"]}></p>}
                        <p {...baseProps["paymentMethod"]}></p>
                        <p {...baseProps["created"]}></p>
                        <input
                            {...baseProps["createdBy"]}
                            type="text"
                            defaultValue={createdBy}
                        />
                        <div {...baseProps["historik"]}></div>
                        {timeAndPlace.mobileServiceLocation &&
                        (timeAndPlace.department
                            ? timeAndPlace.department.mobileService
                            : false) ? (
                            <Dropdown
                                calloutProps={{
                                    calloutMaxHeight: 250,
                                    calloutMinWidth: 100
                                }}
                                dropdownWidth={200}
                                {...baseProps["mobileServiceCovered"]}
                                placeholder="Overdækket"
                            />
                        ) : null}
                        {timeAndPlace.mobileServiceLocation &&
                        (timeAndPlace.department
                            ? timeAndPlace.department.mobileService
                            : false) ? (
                            <SearchPane
                                {...baseProps["mobileServiceAddress"]}
                                placeholder="Adresse"
                                value={address}
                            />
                        ) : null}
                    </EditWorksheetFields>
                </StackItem>
            </Stack>
            <Dialog
                hidden={hideTaskJournalDialog}
                onDismiss={onOpenTaskJournalDismiss}
                dialogContentProps={{
                    type: DialogType.close,
                    title: "Historik"
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: "ms-dialogMainOverride"
                }}
            >
                {(() => {
                    if (taskJournalRecords.length === 0) {
                        return <Label>Opgaven er ikke blevet redigeret</Label>;
                    } else {
                        return taskJournalRecords.map((r, i) => (
                            <div key={i}>
                                <Label>{r.text}</Label>{" "}
                                <a
                                    href="#"
                                    onClick={() => openTaskJournalDiff(r.diff)}
                                >
                                    vis ændringer
                                </a>
                            </div>
                        ));
                    }
                })()}
                <DialogFooter>
                    <DefaultButton onClick={onOpenTaskJournalDismiss}>
                        Luk
                    </DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={hideDiffLogModal}
                onDismiss={onOpenTaskJournalDiffDismiss}
                dialogContentProps={{
                    type: DialogType.close,
                    title: "Ændringer"
                }}
                modalProps={{
                    isBlocking: false,
                    containerClassName: "ms-dialogMainOverride"
                }}
            >
                {(() => {
                    try {
                        let json = JSON.parse(taskJournalSelectedDIff);
                        return (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: formatters.html.format(json, "")
                                }}
                            />
                        );
                    } catch (error) {
                        // json parse failed
                        return null;
                    }
                })()}
                <DialogFooter>
                    <DefaultButton onClick={onOpenTaskJournalDiffDismiss}>
                        Luk
                    </DefaultButton>
                </DialogFooter>
            </Dialog>
        </div>
    );
};
export default QuickInfo;
