import * as React from "react";
import { useCallback, useState } from "react";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { PrimaryButton } from "@fluentui/react";
import ConfirmDialog from "../../../dialogs/confirm";
import { userHasAccess } from "../../../utils/UserHelper";
import FinishReplaceFrontOrderDialog from "../FinishReplaceFrontOrderDialog";
import { getOrderImages } from "../../../../state/action";
import { RootState } from "../../../../state";
import { missingImage } from "../OrderImages/util/ImageUtil";
import ImageIsMissingDialog from "../OrderImages/ImageIsMissingDialog";
import { orderImages } from "~/state/orderImages";


interface Props
{
    order: Order;
    isNotServiceCall: boolean;
    user: UserInformation;
    orderStatus: OrderStatus;
    query: { searchIn?: string; term?: string };
    products: ProductGroup[];
    onSetOrderStatus: (status?: OrderStatus) => () => void;
    onSaveOrderClick: (status?: OrderStatus) => void;
    updateSearchList: (query: { searchIn: string; term: string }, merge: boolean ) => void;
    updateSearchProductQuery: (searchIn?: string, term?: string) => void;
}
const FinishOrder = (props: Props & StateProps) =>
{
    const [hideFinishReplaceFrontOrderDialog, setHideFinishReplaceFrontOrderDialog ] = useState<boolean>(true);
    const [hideMissingImageDialog, setHideMissingImageDialog] = useState<boolean>(true);
    const [hideFinishOrderDialog, setHideFinishOrderDialog] = useState<boolean>(true);
    const toggleFinishReplaceFrontOrderDialog = useCallback(() => setHideFinishReplaceFrontOrderDialog(!hideFinishReplaceFrontOrderDialog), [hideFinishReplaceFrontOrderDialog]);
    const toggleHideMissingImageDialog = useCallback(() => setHideMissingImageDialog(!hideMissingImageDialog), [hideMissingImageDialog]);
    const toggleFinishOrderDialog = useCallback(() => setHideFinishOrderDialog(!hideFinishOrderDialog), [hideFinishOrderDialog]);
    
    const onImageIsNotMissingClick = () =>
    {
        setHideMissingImageDialog(true);
        const isReplaceFront = props.order.additionalData.orderType === "REPLACE_FRONT";
        if(isReplaceFront)
        {
            setHideFinishReplaceFrontOrderDialog(!hideFinishReplaceFrontOrderDialog)
        }
        else
        {
            setHideFinishOrderDialog(!hideFinishOrderDialog)
        }
    }

    const onFinishOrderClick = () =>
    {
        const missingImages = missingImage(props.orderImages);
        if(missingImages>0)
        {
            setHideMissingImageDialog(false);
            return;
        }
        onImageIsNotMissingClick();
        
    }

    const finishOrder = useCallback(() => {
        let status: OrderStatus = "FINISHED";

        if(props.order.additionalData.orderType === "REPLACE_FRONT" && props.order.additionalData.orderStatus === "PROCESSING" )
        {
            status = "PENDING_REVIEW";
        }
        if (props.order.additionalData.orderType.indexOf("COMPLAINT") > -1) {
            if (
                !props.order.complaint ||
                !props.order.complaint.issues ||
                (props.order.complaint.issues.length === 0 &&
                    !props.order.complaint.otherIssue)
            ) {
                alert("Du skal vælge minimum 1 Reklamation årsag !");
                return;
            }
            if (
                props.order.additionalData.orderType !== "INTERNAL_COMPLAINT" &&
                !props.order.additionalData.internalOrderComment
            ) {
                alert("Du skal udfylde Intern Kommentar !");
                return;
            }
            if (
                props.order.additionalData.orderType === "COMPLAINT" &&
                props.order.additionalData.orderStatus !== "SERVICE_CALL"
            ) {
                status = "SERVICE_CALL";
            }
        }
        if (!props.order.additionalData.worker) {
            alert("Du mangler at vælge en montør !");
            return;
        }

        if (!props.order.additionalData.windowManufacturer) {
            if (
                props.order.complaint &&
                props.order.complaint.complaintType !== "REPAIR_SLOT" &&
                props.order.complaint.complaintType === "WITHOUT_SLOT" &&
                props.order.complaint.referenceJobType &&
                props.order.complaint.referenceJobType.startsWith("REPLACE")
            )
                alert("Du mangler at vælge en rudefabrikat !");
            return;
        }

        if (
            props.order.additionalData.windowManufacturer === "NONE" &&
            (props.order.additionalData.orderType.startsWith("REPLACE") ||
                (props.order.additionalData.orderType === "COMPLAINT" &&
                    props.order.complaint &&
                    props.order.complaint.complaintType === "WITHOUT_SLOT" &&
                    props.order.complaint.referenceJobType &&
                    props.order.complaint.referenceJobType.startsWith(
                        "REPLACE"
                    )) ||
                (props.order.additionalData.orderType === "COMPLAINT" &&
                    props.order.complaint &&
                    props.order.complaint.complaintType &&
                    props.order.complaint.complaintType.startsWith("REPLACE")))
        ) {
            alert("Du mangler at vælge en rudefabrikat !");
            return;
        }

        if (
            props.order.complaint &&
            props.order.complaint.workerFault === undefined &&
            props.orderStatus === "SERVICE_CALL"
        ) {
            alert(
                "Du mangler at vælge, om årsagen til klagen er en montørfejl ! Hvis du ikke kan se muligheden, skal du kontakte din administrator."
            );
            return;
        }

        props.onSaveOrderClick(status);
        setHideFinishOrderDialog(true);
        setHideFinishReplaceFrontOrderDialog(true);
    }, [props.order]);

  
    let button = null;
    if (props.order.additionalData.orderStatus === "WEB_REVIEW") {
        button = (
            <PrimaryButton
                style={{
                    background: "#31752f",
                    border: "1px solid #31752f"
                }}
                text="Send til Ordrer"
                onClick={props.onSetOrderStatus("PROCESSING")}
            />
        );
    } else if (props.isNotServiceCall) {
        button = (
            <PrimaryButton
                text={props.order.additionalData.orderStatus === "PENDING_REVIEW" ? "Afslut gennemsyn" : "Afslut/Fakturer"}
                onClick={onFinishOrderClick}
            />
        );
    } else if (userHasAccess(props.user.roles, ["Complaint"])) {
        button = <PrimaryButton text={"Lukket"} onClick={finishOrder} />;
    }
    return <>
        {button}
        <ConfirmDialog
            hideDialog={hideFinishOrderDialog}
            onDismiss={toggleFinishOrderDialog}
            onConfirm={finishOrder}
            title="Afslut ordre"
            subText="Har du husket at tjekke dine varelinjer for lister, clips, småting mv.?"
            confirmText="Ja, Afslut ordre"
            cancelText="Nej, Gå tilbage"
            switchButtons={true}
        />
        <ImageIsMissingDialog 
            hideDialog={hideMissingImageDialog}
            onDismiss={onImageIsNotMissingClick}
            onConfirm={toggleHideMissingImageDialog}
            
        />
        <FinishReplaceFrontOrderDialog
            products={props.products}
            order={props.order}
            hideDialog={hideFinishReplaceFrontOrderDialog}
            onDismiss={toggleFinishReplaceFrontOrderDialog}
            updateSearchProductQuery={props.updateSearchProductQuery}
            updateSearchList={props.updateSearchList}
            query={props.query}
            onFinishOrder={finishOrder}
        />
    </>;
}
const mapStateToProps = (state: RootState) => {
    return {
        orderImages: getOrderImages(state),
    };
};
const mapToDispatchProps = (dispatch: Dispatch<Action>) => ({
});


type StateProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapToDispatchProps>;

export default connect(mapStateToProps, mapToDispatchProps)(FinishOrder);